  
  .containern {
    width: 100%;
    height: 100vh;
    top: 10%;
    left: 10%;
    background-color: #fff;
  }
  
  .sigContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
  }
  
  .sigPad {
    width: 50%;
    height: 50%;
    background-color: #fff;
  }

  /* canvas {
    width: 50%;
    height: 50%;
    background-color: #fff;
  } */
  
  .buttons {
    width: 100%;
    height: 30px;
  }
  
  .sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;

 body {
    background-color: rgb(229, 231, 235);
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  } 

  @layer base {
    
    canvas {
      @apply bg-white w-full h-96 md:w-2/3;
    }
  }
  @layer components {
    .button-bf {
      @apply text-white bg-gray-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center;
    }
    .input-bf {
      @apply border text-sm rounded block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500;
    }
    .input-label {
      @apply block mb-2 text-sm font-medium text-gray-900;
    }
    .main-card {
      @apply my-4 w-11/12 lg:w-7/12 px-4 mx-2 mx-auto pt-6  break-words mb-6 shadow-lg rounded-lg border-0 bg-white;
    }
    .header-label {
      @apply text-3xl font-bold;
    }
    .medium-text {
      @apply text-lg font-medium;
    }
    .normal-text {
      @apply font-medium;
    }

    .table-cell {
      @apply flex flex-col sm:table-row px-6 py-3;
    }

    .mobile-transcat-cell {
      @apply p-1;
    }
  }